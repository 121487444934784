import React, {
  useState,
  useCallback,
  forwardRef,
  useRef,
  useMemo,
  useEffect,
} from 'react'
import YouTubePlayer from 'yt-player'
import classNames from 'classnames'
import Iframe from '@/components/common/Iframe'
import InView from '@/components/common/InView'
import useYoutubeBackgroundImage from '@/services/hooks/useYoutubeBackgroundImage'
import { AngelFundingLogo } from '@/components/svg'
import VideoBoxPlayerWrap from './VideoBoxPlayerWrap'

const defaultAllow =
  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'

const defaultParams = {
  autoplay: 0,
  mute: 0,
  controls: 1,
  playsinline: 1,
  showinfo: 0,
  rel: 0,
  iv_load_policy: 3,
  modestbranding: 1,
  enablejsapi: 1,
}

export interface Props {
  id: string
  video: string
  aspectRatio?: number
  useThumbnailBackground?: boolean
  lazyload?: boolean
  fadeInOnLoad?: boolean
  className?: string
  style?: React.CSSProperties
  params?: Partial<typeof defaultParams>
  allow?: string
  campaignSlug: string
  doSegmentTracking?: boolean
  autoplay?: boolean
  onPlaying?: () => void
  onPaused?: () => void
  onEnded?: () => void
}

const VideoBox = (
  {
    id,
    video,
    aspectRatio = 16 / 9,
    useThumbnailBackground = true,
    lazyload = true,
    fadeInOnLoad = true,
    className,
    style = {},
    params = {},
    allow = defaultAllow,
    campaignSlug,
    doSegmentTracking = true,
    autoplay = false,
    onPlaying,
    onPaused,
    onEnded,
  }: Props,
  ref?: React.ForwardedRef<YouTubePlayer | null>
) => {
  const [inView, setInView] = useState(!lazyload)
  const [origin, setOrigin] = useState('')
  const { src: backgroundSrc, isLoaded: backgroundLoaded } =
    useYoutubeBackgroundImage({
      video,
      disabled: !inView || !useThumbnailBackground,
    })
  const forwardedPlayerRef = ref as { current: YouTubePlayer }
  const defaultPlayerRef = useRef(null)
  const playerRef = forwardedPlayerRef || defaultPlayerRef
  const showImage = useThumbnailBackground && backgroundLoaded

  const onEnter = useCallback(() => {
    if (lazyload) {
      setInView(true)
    }
  }, [lazyload, setInView])

  const paddingBottom = Number.isNaN(aspectRatio)
    ? `${(9 / 16) * 100}%`
    : `${100 / aspectRatio}%`

  useEffect(() => setOrigin(window.location.origin), [])

  const iframeSrc = useMemo(() => {
    if (!video || !origin) return ''

    return Object.entries({
      ...defaultParams,
      ...params,
      origin,
    }).reduce(
      (src, [key, val]) => `${src}&${key}=${encodeURIComponent(val)}`,
      `https://www.youtube.com/embed/${video}?`
    )
  }, [params, video, origin])

  return (
    <InView
      onEnter={onEnter}
      unobserveAfterEntry
      className={classNames('relative', className)}
      style={{ paddingBottom, ...style }}
    >
      {showImage && (
        <div
          className="absolute inset-0 w-full"
          style={{
            backgroundImage: `url(${backgroundSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      )}

      <div
        className="absolute inset-0 bg-gray-8 flex items-center justify-center p-6"
        style={{
          pointerEvents: showImage ? 'none' : 'auto',
          opacity: showImage ? 0 : 1,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <AngelFundingLogo fill="#B0B0B0" className="max-w-lg" />
      </div>

      {inView && iframeSrc && (
        <VideoBoxPlayerWrap
          playerRef={playerRef}
          id={id}
          video={video}
          autoplay={autoplay}
          doSegmentTracking={doSegmentTracking}
          campaignSlug={campaignSlug}
          onPlaying={onPlaying}
          onPaused={onPaused}
          onEnded={onEnded}
        >
          <Iframe
            id={id}
            data-cy="video-player"
            src={iframeSrc}
            allow={allow}
            className="absolute inset-0"
            width="100%"
            height="100%"
            fadeInOnLoad={fadeInOnLoad}
          />
        </VideoBoxPlayerWrap>
      )}
    </InView>
  )
}

export default forwardRef<YouTubePlayer | null, Props>(VideoBox)

import React, { useState, useEffect, useRef } from 'react'
import YouTubePlayer from 'yt-player'
import { getYoutubeVideoSegmentProperties, report } from '@/utils'
import SegmentHandler from '@/services/analytics/SegmentHandler'

export interface Props {
  playerRef: { current: YouTubePlayer | null }
  id: string
  video: string
  campaignSlug: string
  doSegmentTracking?: boolean
  autoplay?: boolean
  onPlaying?: () => void
  onPaused?: () => void
  onEnded?: () => void
}

const VideoBoxPlayerWrap: React.FC<Props> = ({
  playerRef,
  id,
  video,
  autoplay,
  doSegmentTracking = true,
  campaignSlug,
  onPlaying,
  onPaused,
  onEnded,
  children,
}) => {
  // If an error occurs in the youtube iframe api, then the iframe is removed
  // from the DOM, but React has no knowledge of the removal. This renderKey
  // is incremented in the iframe api error handler and is used to tell React
  // to rerender the iframe
  const [renderKey, setRenderKey] = useState(1)

  const videoPlayed = useRef('')

  useEffect(() => {
    if (!video || !document.getElementById(id)) return

    try {
      playerRef.current = new YouTubePlayer(`#${id}`)

      playerRef.current.load(video)

      playerRef.current.on('cued', () => {
        if (autoplay) {
          playerRef.current?.play()
        }
      })

      playerRef.current.on('error', () => {
        setRenderKey((cur) => cur + 1)
        const err = new Error(`Error playing youtube video ${video}`)
        report.error(err)
      })

      playerRef.current.on('unplayable', () => {
        const err = new Error(`Unplayable video ${video}`)
        report.error(err)
      })

      playerRef.current.on('playing', () => {
        onPlaying?.()

        // avoid re-tracking if content paused/restarted
        if (videoPlayed.current === video) {
          return
        }

        videoPlayed.current = video

        if (doSegmentTracking) {
          SegmentHandler.track(
            'Video Content Started',
            getYoutubeVideoSegmentProperties(playerRef, campaignSlug)
          )
        }
      })

      playerRef.current.on('paused', () => {
        onPaused?.()

        if (doSegmentTracking) {
          SegmentHandler.track(
            'Video Playback Paused',
            getYoutubeVideoSegmentProperties(playerRef, campaignSlug)
          )
        }
      })

      playerRef.current.on('ended', () => {
        onEnded?.()

        if (doSegmentTracking) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { position, ...videoProperties } =
            getYoutubeVideoSegmentProperties(playerRef, campaignSlug)
          SegmentHandler.track('Video Content Ended', videoProperties)
        }
      })
    } catch (err) {
      setRenderKey((cur) => cur + 1)
      const error = err as Error
      error.message = `Error initializing youtube iframe api for video ${video}`
      report.error(error)
    }
  }, [
    id,
    video,
    playerRef,
    campaignSlug,
    doSegmentTracking,
    autoplay,
    onPlaying,
    onPaused,
    onEnded,
    setRenderKey,
  ])

  useEffect(() => {
    return () => {
      playerRef.current?.destroy()
    }
  }, [playerRef])

  return (
    <div key={renderKey} className="absolute inset-0">
      {children}
    </div>
  )
}

export default VideoBoxPlayerWrap
